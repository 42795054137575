import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';

function DialogComp(props) {
    const { onClose, open, actionType } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            PaperProps={{
                style: {
                    padding: "10px",
                    width: "330px", 
                    textAlign: "center"
                },
            }}
        >
            {actionType === "CREATE_AI_VIDEO" ? (
                <Typography
                   component="div"
                   variant='p'
                >
                    You need to create credentials to generate sample videos. Please contact us at
                    <span style={{ color: "rgb(255, 138, 0)" }}> info@kroop.ai </span> to schedule a call
                    or to learn more about The Artiste Studio.
                </Typography>
            ) : (
                <Typography
                   component="div"
                   variant='p'
                >
                    This feature is no longer available as part of the free trial. Please contact us at
                    <span style={{ color: "rgb(255, 138, 0)" }}> info@kroop.ai </span> to schedule 
                    a call or to learn more about Kroop AI’s deepfake detector.
                </Typography>
            )}
        </Dialog>
    );
}

export default DialogComp;
