import React from "react";
import { Button, Box, Grid, Typography, Card } from "@mui/material";
import tickIcon from "../../assets/tickIcon.svg";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

const PricingCard = ({ plan, cardIndex, buttonVisible }) => {
    return (
        <Box
            sx={{
                background: cardIndex === 2 ? "#404452" : "#F2F2F2",
                color: cardIndex === 2 ? "#fff" : "#000",
                p: 2,
                m: 2,
                height: "370px",
                borderRadius: "20px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                // width: "300px",
                boxShadow: `0px 2px 10px ${
                    cardIndex === 2 ? "#404452" : "#D7D7D7"
                }}`,
            }}
        >
            <Box sx={{ p: 1 }}>
                <div
                    style={{
                        visibility: buttonVisible ? "visible" : "hidden",
                        display: "flex",
                        justifyContent: "right",
                        alignItems: "right",
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            background: "#fff",
                            color: "#231D4F",
                            borderRadius: "50px",
                            textTransform: "none",
                            padding: "2px 10px 2px 10px",
                            cursor: "default",
                            fontSize: "8px",
                        }}
                    >
                        Most Popular
                    </Button>
                </div>

                <h4>
                    {plan.amount}{" "}
                    <span style={{ fontSize: "13px" }}>/ {plan.period}</span>
                </h4>
                <h4 style={{ marginTop: "10px" }}>{plan.label}</h4>
                <p
                    style={{
                        fontSize: "12px",
                        marginTop: "5px",
                    }}
                >
                    {plan.description}
                </p>
                <ul style={{ margin: "0px", padding: "0px" }}>
                    {plan.items.map((item, index) => (
                        <li style={{ listStyle: "none", marginTop: "10px" }}>
                            {item.available ? (
                                <CheckCircleRoundedIcon
                                    style={{
                                        color:
                                            cardIndex === 2
                                                ? "#D9D9D9"
                                                : "#404452",
                                    }}
                                />
                            ) : (
                                <CancelIcon style={{ color: "#D9D9D9" }} />
                            )}
                            <span
                                style={{
                                    marginLeft: "10px",
                                }}
                            >
                                {item.label}
                            </span>
                        </li>
                    ))}
                </ul>
            </Box>
            <Button
                variant="contained"
                style={{
                    marginTop: "10px",
                    background: cardIndex === 2 ? "#fff" : "#404452",
                    color: cardIndex === 2 ? "#404452" : "#fff",
                    borderRadius: "50px",
                    textTransform: "none",
                    // width: "94%",
                    "&:hover": {
                        backgroundColor: cardIndex === 2 ? "#fff" : "#404452",
                    },
                    boxShadow: "none",
                }}
                onClick={() =>
                    (window.location.href =
                        "https://studio.theartiste.ai/app/register")
                }
            >
                Choose Plan
            </Button>
        </Box>
    );
};

export default PricingCard;
