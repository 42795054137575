import React, { useState } from "react";
import {
  TextField,
  Button,
  Grid,
  Container,
  Alert,
  AlertTitle,
} from "@mui/material";
import axios from "axios";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    organization: "",
    message: "",
  });

  const [alert, setAlert] = useState({
    show: false,
    type: "success", // 'success' or 'error'
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("/external/enquiry", formData) // Replace with your API endpoint
      .then((response) => {
        setAlert({
          show: true,
          type: "success",
          message: "Form has been submitted!",
        });
      })
      .catch((error) => {
        setAlert({
          show: true,
          type: "error",
          message:
            "An error occurred while submitting the form. Please try again later.",
        });
      });
  };

  return (
    <Container maxWidth="sm"
      style={{
        backgroundColor: "#F5FBFF",
        borderRadius: "5px"
      }}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Name and Email fields in the same row for laptop */}
          <Grid item xs={12} sm={6}>
            <TextField
              label="Name"
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Email"
              fullWidth
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Organization"
              fullWidth
              name="organization"
              value={formData.organization}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message"
              fullWidth
              multiline
              rows={4}
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} align="center">
            <Button
              className="btn-rounded"
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              style={{
                backgroundColor: "#0267FF",
                border: "#0267FF",
                textTransform: "capitalize",
                boxShadow: "none",
              }}
            >
              Submit
            </Button>
          </Grid>
          {alert.show && (
            <Grid item xs={12}>
              <Alert severity={alert.type}>
                <AlertTitle>
                  {alert.type === "success" ? "Success" : "Error"}
                </AlertTitle>
                {alert.message}
              </Alert>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
};

export default ContactForm;
