import React, { useState } from "react";
import { Button, Box, Grid, Typography, Card } from "@mui/material";
import { PLANS, PLAN_DETAILS } from "../data/billing";
import tickIcon from "../assets/tickIcon.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import PricingCard from "./CardComponent/PricingCard";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const CSS_POPUPLAR_PLAN = {
    background: "#404452",
};

const PremiumPlan = ({ plan, index = 2 }) => {
    return (
        <Grid item xs={3} key={index}>
            <Box
                sx={{
                    transform: "translateY(-80px)",
                    background: "#404452",
                    height: "400px",
                    borderRadius: "20px",
                    width: "300px",
                    zIndex: 99,
                    position: "absolute",
                    boxShadow: "0px 20px 20px #888",
                }}
            >
                <Box style={{ marginLeft: "20px", marginTop: "10px" }}>
                    <div className="row">
                        <div className="col-5"></div>
                        <div className="col-7">
                            <Button
                                variant="contained"
                                style={{
                                    background: "#fff",
                                    color: "#231D4F",
                                    borderRadius: "50px",
                                    textTransform: "none",
                                    // width: "94%",
                                    padding: "5px 20px 5px 20px",
                                    margin: "5px",
                                    cursor: "default",
                                    boxShadow: "none",
                                }}
                            >
                                Most Popular
                            </Button>
                        </div>
                    </div>
                    <h4 style={{ color: "#fff" }}>
                        {plan.amount}{" "}
                        <span style={{ color: "#fff", fontSize: "15px" }}>
                            / {plan.period}
                        </span>
                    </h4>
                    <h4 style={{ color: "#fff", marginTop: "15px" }}>
                        {plan.label}
                    </h4>
                    <p
                        style={{
                            color: "#fff",
                            fontSize: "15px",
                            marginTop: "10px",
                        }}
                    >
                        {plan.description}
                    </p>
                    <ul style={{ margin: "0px", padding: "0px" }}>
                        {plan.items.map((item, index) => (
                            <li
                                style={{ listStyle: "none", marginTop: "10px" }}
                            >
                                {item.available ? (
                                    <img src={tickIcon} />
                                ) : (
                                    <CancelIcon style={{ color: "#D9D9D9" }} />
                                )}
                                <span
                                    style={{
                                        marginLeft: "10px",
                                        color: "#fff",
                                    }}
                                >
                                    {item.label}
                                </span>
                            </li>
                        ))}
                    </ul>
                    <Button
                        variant="contained"
                        style={{
                            marginTop: "25px",
                            background: "#fff",
                            color: "#231D4F",
                            borderRadius: "50px",
                            textTransform: "none",
                            width: "94%",
                            "&:hover": {
                                backgroundColor: "#FFFF",
                            },
                            boxShadow: "none",
                        }}
                        onClick={() =>
                            (window.location.href =
                                "https://studio.theartiste.ai/app/register")
                        }
                    >
                        Choose Plan
                    </Button>
                </Box>
            </Box>
        </Grid>
    );
};

const BillingDetails = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [expandedAccordion, setExpandedAccordion] = useState(false);

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpandedAccordion(isExpanded ? panel : false);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    return (
        <>
            <Box
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    overflowX: "hidden",
                    background: "#F4F4F4",
                }}
                sx={{
                    display: { md: "block", xs: "none" },
                }}
            >
                <Box
                    sx={{
                        marginTop: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        flexDirection: "column",
                    }}
                >
                    <Typography variant="h4" sx={{ color: "#231D4F" }}>
                        Simple, transparent pricing for generating videos
                    </Typography>
                    <p style={{ color: "#848199", marginTop: "10px" }}>
                        No contracts. No surprise fees.
                    </p>
                    {/* <Box style={{ display: "flex" }}>
          <Button
            variant="contained"
            style={{ background: "#0267FF", borderRadius: "10px" }}
          >
            MONTHLY
          </Button>
          <Button
            variant="contained"
            style={{
              background: "#ffff",
              color: "black",
              boxShadow: "none",
              borderRadius: "10px",
            }}
          >
            YEARLY
          </Button>
        </Box> */}
                    <Card
                        style={{
                            width: "90%",
                            background: "#FFFFFF",
                            marginTop: "100px",
                            borderRadius: "20px",
                        }}
                    >
                        <Grid container spacing={1}>
                            {PLANS.map((plan, index) =>
                                index === 2 ? (
                                    <PremiumPlan plan={plan} />
                                ) : (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={3}
                                        md={3}
                                        key={index}
                                    >
                                        <Box sx={{ margin: "15px" }}>
                                            <h4 style={{ color: "#231D4F" }}>
                                                {plan.amount}{" "}
                                                <span
                                                    style={{
                                                        color: "#848199",
                                                        fontSize: "15px",
                                                    }}
                                                >
                                                    / {plan.period}
                                                </span>
                                            </h4>
                                            <h4
                                                style={{
                                                    color: "#231D4F",
                                                    marginTop: "15px",
                                                }}
                                            >
                                                {plan.label}
                                            </h4>
                                            <p
                                                style={{
                                                    color: "#848199",
                                                    fontSize: "15px",
                                                    marginTop: "10px",
                                                }}
                                            >
                                                {plan.description}
                                            </p>
                                            <ul
                                                style={{
                                                    margin: "0px",
                                                    padding: "0px",
                                                }}
                                            >
                                                {plan.items.map(
                                                    (item, index) => (
                                                        <li
                                                            style={{
                                                                listStyle:
                                                                    "none",
                                                                marginTop:
                                                                    "10px",
                                                            }}
                                                        >
                                                            {item.available ? (
                                                                <img
                                                                    src={
                                                                        tickIcon
                                                                    }
                                                                />
                                                            ) : (
                                                                <CancelIcon
                                                                    style={{
                                                                        color: "#D9D9D9",
                                                                    }}
                                                                />
                                                            )}
                                                            <span
                                                                style={{
                                                                    marginLeft:
                                                                        "10px",
                                                                    color: "#848199",
                                                                }}
                                                            >
                                                                {item.label}
                                                            </span>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                            <Button
                                                variant="contained"
                                                style={{
                                                    marginTop: "15px",
                                                    background: "#E9E9EE",
                                                    color: "#231D4F",
                                                    borderRadius: "50px",
                                                    textTransform: "none",
                                                    width: "100%",
                                                    "&:hover": {
                                                        backgroundColor:
                                                            "#FFFF",
                                                    },
                                                }}
                                                onClick={() =>
                                                    (window.location.href =
                                                        "https://studio.theartiste.ai/app/register")
                                                }
                                            >
                                                Choose Plan
                                            </Button>
                                        </Box>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </Card>
                </Box>
                {/*section 2 */}
                <Box
                    style={{
                        width: "90%",
                        margin: "40px auto",
                    }}
                >
                    <Typography
                        variant="h3"
                        sx={{
                            color: "#231D4F",
                            fontSize: "2em",
                            fontWeight: 600,
                        }}
                    >
                        Simple, transparent pricing for generating videos
                    </Typography>
                    <p style={{ color: "#848199", marginTop: "10px" }}>
                        No contracts. No surprise fees.
                    </p>
                    <br />
                    <Grid container>
                        <Grid item xs={2.4} />
                        {[
                            "Basic(Free)",
                            "Standard",
                            "Premium",
                            "Enterprise",
                        ].map((plan, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    fontSize: 22,
                                    fontWeight: 500,
                                    color:
                                        plan === "Standard"
                                            ? "#0267FF"
                                            : "#404452",
                                }}
                            >
                                {plan}
                            </Grid>
                        ))}
                        <br />
                        <br />
                        <br />
                        <Grid item xs={2.4} />
                        {["Free", "$12", "$60", "Custom"].map(
                            (price, index) => (
                                <Grid
                                    item
                                    xs={2.4}
                                    key={index}
                                    style={{
                                        fontSize: 20,
                                        fontWeight:"500",
                                    }}
                                >
                                    {price}{" "}
                                    <span style={{ color: "#848199" }}>
                                        / {index === 0 ? "7 days" : "month"}
                                    </span>
                                </Grid>
                            )
                        )}
                        <br />
                        <br />
                        <br />
                        {[
                            "Minutes",
                            "10 Minutes",
                            "15 Minutes",
                            "80 Minutes",
                            ">500 Minutes",
                        ].map((price, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {price}
                            </Grid>
                        ))}
                        <br />
                        <br />
                        <br />
                        {[
                            "Price/Minute",
                            <ClearIcon style={{ color: "red" }} />,
                            "$ 1 per minute",
                            "$ 0.75 per minute",
                            "Contact Sales",
                        ].map((price, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {price}
                            </Grid>
                        ))}
                        <br />
                        <br />
                        <br />
                        {[
                            "Support",
                            <ClearIcon style={{ color: "red" }} />,
                            <CheckIcon style={{ color: "#848199" }} />,
                            <CheckIcon style={{ color: "#848199" }} />,
                            <CheckIcon style={{ color: "#848199" }} />,
                        ].map((price, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {price}
                            </Grid>
                        ))}
                        {/* <br />
                        <br />
                        <br /> */}
                        {/* {[
                            "Duration",
                            "7 days",
                            "7 days",
                            "7 days",
                            "7 days",
                        ].map((price, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {price}
                            </Grid>
                        ))} */}
                        <br />
                        <br />
                        <br />
                        {[
                            "Number of users",
                            "1 user",
                            "5 user",
                            "20 user",
                            "No limit",
                        ].map((price, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {price}
                            </Grid>
                        ))}
                        <br />
                        <br />
                        <br />
                        {[
                            "Avatars",
                            "Basic Avatars",
                            "25 Avatars",
                            "All Avatars",
                            "Custom Avatars",
                        ].map((price, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {price}
                            </Grid>
                        ))}
                        <br />
                        <br />
                        <br />
                        {[
                            "Voices",
                            "Basic English (US)",
                            "20 Multi Lingual ",
                            "All Voices",
                            "Custom Voice Cloning",
                        ].map((price, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {price}
                            </Grid>
                        ))}
                        <br />
                        <br />
                        <br />
                        {[
                            "Background Audios",
                            "Basic Stock",
                            "Upload your own upto 5",
                            "Upload your own upto 20",
                            "No limit",
                        ].map((price, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {price}
                            </Grid>
                        ))}
                        <br />
                        <br />
                        <br />
                        {[
                            "Background Videos",
                            "Basic Stock (SD)",
                            "Basic + Upload your own upto 5",
                            "HD Stock + Upload your own upto 20",
                            "GenAI backgrounds",
                        ].map((price, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {price}
                            </Grid>
                        ))}
                        <br />
                        <br />
                        <br />
                        {[
                            "Slides",
                            "_",
                            <CheckIcon style={{ color: "#848199" }} />,
                            "Upload your slides",
                            "As per needs",
                        ].map((price, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {price}
                            </Grid>
                        ))}
                        <br />
                        <br />
                        <br />
                        {[
                            "Miscallenous",
                            "_",
                            "_",
                            "Backgrounds based on Content (Integrated ChatGPT**)",
                            "Backgrounds based on Content (Integrated dynamic backgrund***)",
                        ].map((price, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {price}
                            </Grid>
                        ))}
                        {[1, 2, 3, 4, 5].map((value, index) => (
                            <Grid
                                item
                                xs={2.4}
                                key={index}
                                style={{
                                    color: index === 0 ? "#848199" : "#231D4F",
                                    fontWeight: 100,
                                }}
                            >
                                {value > 1 && (
                                    <Button
                                        style={{
                                            marginTop: "25px",
                                            background:
                                                value === 3
                                                    ? "#0267ff"
                                                    : "#404452",
                                            color: "#FFFFFF",
                                            borderRadius: "50px",
                                            textTransform: "none",
                                            width: "94%",
                                            "&:hover": {
                                                backgroundColor: "#FFFF",
                                            },
                                            boxShadow: "none",
                                        }}
                                        onClick={() =>
                                            (window.location.href =
                                                "https://studio.theartiste.ai/app/register")
                                        }
                                    >
                                        Choose Plan
                                    </Button>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>

            {/* Billing Details | Mobile View */}
            <Box
                sx={{
                    display: { xs: "flex", md: "none" },
                    mt: 12,
                    ml: 5,
                    mr: 5,
                    mb: 5,
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ color: "#231D4F", textAlign: "center" }}
                >
                    Simple, transparent pricing for generating videos
                </Typography>
                <p
                    style={{
                        color: "#848199",
                        fontSize: "12px",
                        textAlign: "center",
                    }}
                >
                    No contracts. No surprise fees.
                </p>
                <AutoPlaySwipeableViews
                    axis={"x"}
                    index={activeStep}
                    onChangeIndex={handleStepChange}
                    enableMouseEvents
                >
                    {PLANS.map((plan, index) => (
                        <div key={index}>
                            <PricingCard plan={plan} cardIndex={index} buttonVisible={index===2 ? true : false} />
                        </div>
                    ))}
                </AutoPlaySwipeableViews>
                <Box sx={{ p: 2 }}>
                    {PLAN_DETAILS.map((plan, index) => (
                        <Accordion
                            key={index}
                            expanded={expandedAccordion === `panel${index + 1}`}
                            onChange={handleAccordionChange(
                                `panel${index + 1}`
                            )}
                            sx={{ mb: 2, p:1, boxShadow: "0px 2px 10px #D7D7D7" }}
                        >
                            <AccordionSummary
                                expandIcon={<KeyboardDoubleArrowDownIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                style={{
                                    flexDirection: 'column'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        textAlign: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Typography
                                        sx={{ color: index === 1 && "#0267ff" }}
                                    >
                                        {plan.label}
                                    </Typography>
                                    <Typography variant="h6" sx={{}}>
                                        {plan.price}/
                                        <span style={{ fontSize: "13px" }}>
                                            {plan.period}
                                        </span>
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        style={{
                                            marginTop: "5px",
                                            width: "170px",
                                            background:
                                                index === 1
                                                    ? "#0267ff"
                                                    : "#404452",
                                            color: "#FFF",
                                            borderRadius: "50px",
                                            textTransform: "none",
                                            "&:hover": {
                                                backgroundColor:
                                                    index === 1
                                                        ? "#0267ff"
                                                        : "#404452",
                                            },
                                        }}
                                        onClick={() =>
                                            (window.location.href =
                                                "https://studio.theartiste.ai/app/register")
                                        }
                                    >
                                        Choose Plan
                                    </Button>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails sx={{height:"100%"}}>
                                <hr style={{ marginTop: 0 }} />
                                <Grid container>
                                    <Grid item xs sx={{ fontSize: "10px", mr:2 }}>
                                        <Typography sx={{fontSize:"10px", mb:1}}>Minutes</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>Price/Minute</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>Support</Typography>
                                        {/* <Typography sx={{fontSize:"10px", mb:1}}>Duration</Typography> */}
                                        <Typography sx={{fontSize:"10px", mb:1}}>Number of users</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>Avatars</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>Voices</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1, height:"30px"}}>Background Audios</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1, height:"30px"}}>Background Videos</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>Slides</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>Miscallenous</Typography>
                                    </Grid>
                                    <Grid item xs style={{ fontSize: "10px" }}>
                                        <Typography sx={{fontSize:"10px", mb:1}}>{plan.minutes}</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>{plan.perMinute}</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>{plan.support ? <CheckIcon sx={{ fontSize: "12px" }} /> : <ClearIcon sx={{ fontSize: "12px", color: "#f00" }} /> }</Typography>
                                        {/* <Typography sx={{fontSize:"10px", mb:1}}>{plan.duration}</Typography> */}
                                        <Typography sx={{fontSize:"10px", mb:1}}>{plan.number_of_users}</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>{plan.avatars}</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>{plan.voices}</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1, height:"30px"}}>{plan.background_audios}</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1, height:"30px"}}>{plan.background_videos}</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>{plan.slides}</Typography>
                                        <Typography sx={{fontSize:"10px", mb:1}}>{plan.miscallenous}</Typography>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            </Box>
        </>
    );
};
export default BillingDetails;
