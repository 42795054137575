export const PLANS = [
    {
      type: "BASIC",
      label: "Basic",
      amount: "Free",
      description: "For most businesses that want to otpimize web queries",
      period: "7days",
      items: [
        // {
        //   label: "7 Days",
        //   available: true,
        // },
        {
          label: "10 free minutes",
          available: true,
        },
        {
          label: "Basic support",
          available: false,
        },
        {
          label: "Single user",
          available: true,
        },
        {
          label: "10% Avatars",
          available: true,
        },
      ],
    },
    {
      type: "STANDARD",
      label: "Standard",
      amount: "$12",
      period: "month",
      description: "For most businesses that want to otpimize web queries",
      items: [
        // {
        //   label: "7 Days",
        //   available: true,
        // },
        {
          label: "15 free minutes",
          available: true,
        },
        {
          label: "Basic support",
          available: true,
        },
        {
          label: "Upto 5 user",
          available: true,
        },
        {
          label: "50% Avatars",
          available: true,
        },
      ],
    },
    {
      type: "PREMIUM",
      label: "Premium",
      amount: "$60",
      period: "month",
      description: "For most businesses that want to otpimize web queries",
      items: [
        // {
        //   label: "7 Days",
        //   available: true,
        // },
        {
          label: "80 free minutes",
          available: true,
        },
        {
          label: "Priority support",
          available: true,
        },
        {
          label: "Upto 20 user",
          available: true,
        },
        {
          label: "100% Avatars",
          available: true,
        },
      ],
    },
    {
      type: "Enterprise",
      label: "Enterprise",
      amount: "Custom",
      period: "month",
      description: "For most businesses that want to otpimize web queries",
      items: [
        // {
        //   label: "7 Days",
        //   available: true,
        // },
        {
          label: "> 500 minutes",
          available: true,
        },
        {
          label: "Priority support",
          available: true,
        },
        {
          label: "Custom",
          available: true,
        },
        {
          label: "100% Avatars",
          available: true,
        },
      ],
    },
  ];

  export const PLAN_DETAILS = [
    {
      type: "BASIC",
      label: "Basic(Free)",
      price: "$0",
      period: "month",
      duration: "7 days",
      minutes:"10 Minutes",
      support: false,
      number_of_users: 1,
      avatars: "Basic Avatars",
      voices:"Basic English (US)",
      background_audios: "Basic Stock",
      background_videos: "Basic Stock (SD)",
      slides: "-",
      miscallenous: "-",
      perMinute: "-"
    },
    {
      type: "STANDARD",
      label: "Standard",
      price: "$100",
      period: "month",
      duration: "7 days",
      minutes:"15 Minutes",
      support: true,
      number_of_users: 5,
      avatars: "20 Avatars",
      voices:"20 Multi Lingual",
      background_audios: "Upload your own upto 5",
      background_videos: "Basic + Upload your own upto 5",
      slides: "-",
      miscallenous: "-",
      perMinute: "$ 1 per minute"
    },
    {
      type: "PREMIUM",
      label: "Premium",
      price: "$200",
      period: "month",
      duration: "7 days",
      minutes:"80 Minutes",
      support: true,
      number_of_users: 20,
      avatars: "All Avatars",
      voices:"All Voices",
      background_audios: "Upload your own upto 20",
      background_videos: "HD Stock + Upload your own upto 20",
      slides: "Upload your slides",
      miscallenous: "Backgrounds based on Content (Integrated ChatGPT**)",
      perMinute: "$ 0.75 per minute"
    },
    {
      type: "ENTERPRISE",
      label: "Enterprise",
      price: "Custom",
      period: "month",
      duration: "7 days",
      minutes:">500 Minutes",
      support: true,
      number_of_users: "No limit",
      avatars: "Custom Avatars",
      voices:"Custom Voice",
      background_audios: "No limit",
      background_videos: "GenAI backgrounds",
      slides: "As per needs",
      miscallenous: "Backgrounds based on Content (Integrated dynamic backgrund***)",
      perMinute: "Contact Sales"
    },
  ];
  