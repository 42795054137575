import { Box, Grid, Typography } from "@mui/material";
import Deepfake1 from "../assets/deepfake1.jpg";
import Deepfake2 from "../assets/deepfake2.jpg";
import ContactForm from "./ContactForm";

const DeepfakePage = () => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#F6FAFD"
      }}
    >
      <Box
        sx={{
          marginTop: "120px",
          textAlign: "center",
          width: {
            xs: "90%",
            sm: "80%",
            md: "70%",
            lg: "80%",
          },
        }}
      >
        <Typography variant="h3">
          <span
            style={{
              color: "#007DFE",
              display: "inline-block",
              marginLeft: "5px",
            }}
          >
            VizMantiz
          </span>
          - Kroop AI’s
          <span
            style={{
              color: "rgb(255,138,0)",
              display: "inline-block",
              marginLeft: "5px",
            }}
          >
            Deepfake Detector
          </span>
        </Typography>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography
            style={{
              marginTop: "20px",
            }}
          >
            Deepfake technology has advanced rapidly, enabling the creation of highly realistic fake videos, audio, and images.
            Its accessibility poses a threat to the authenticity of digital content, often being misused to spread misinformation,
            manipulate public opinion, and influence elections.
            Deepfakes can also invade personal privacy by fabricating content, raising ethical concerns about responsible technology use.
            <br /><br />
          </Typography>
          <Typography
            style={{
              textAlign: "center",
            }}
            variant="h6"
          >
            Kroop AI's deepfake detector is a powerful solution for detecting and mitigating deepfakes.
            Key use cases include:
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: "30px",
          textAlign: "left",
        }}
        sx={{
          width: {
            xs: "90%",
            sm: "80%",
            md: "70%",
            lg: "80%",
          },
        }}
      >
        <Grid
          container
          alignItems="center"
        >
          <Grid item xs={12} sm={6} md={6}>
            <ul>
              <li>
                <b>Digital Forensics:</b>  Identifies fake media by analyzing 
                inconsistencies in lighting, shadows,
                facial expressions, lip-sync, and eye movements.
              </li>
              <li
                style={{
                  marginTop: "20px",
                }}
              >
                <b>Image and Video Analysis:</b> Uses metadata analysis 
                to detect anomalies and artifacts
                from deepfake generation.
              </li>
              <li
                style={{
                  marginTop: "20px",
                }}
              >
                <b>Biometric Analysis:</b> Compares facial landmarks 
                and micro-expressions 
                to known datasets, making it highly effective 
                for online biometric verification.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  width: "300px",
                }}
              >
                <img
                  src={Deepfake1}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box
                style={{
                  width: "300px",
                  marginTop: "10px"
                }}
              >
                <img
                  src={Deepfake2}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          textAlign: "center",
          marginTop: "20px",
        }}
        sx={{
          width: {
            xs: "90%",
            sm: "80%",
            md: "70%",
            lg: "80%",
          },
        }}
      >
        <Box>
          <Typography
            variant="h6"
          >
            Please contact us at{" "}
            <span style={{ color: "green" }}>info@kroop.ai</span> or fill in the form below
            to schedule a call or to learn more about VizMantiz – Kroop AI’s
            Deepfake Detector solution and its usage.
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: "30px",
        }}
      >
        <ContactForm />
      </Box>
    </Box>
  );
};

export default DeepfakePage;
