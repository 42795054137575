import {  Box, Grid, Typography } from "@mui/material";
import Artiste1 from "../assets/Artiste1.jpg";
import Artiste2 from "../assets/Artiste2.jpg";
import ContactForm from "./ContactForm";

const GenerateVideo = () => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#F6FAFD"
      }}
    >
      <Box
        sx={{
          marginTop: "120px",
          textAlign: "center",
          width: {
            xs: "90%",
            sm: "80%",
            md: "70%",
            lg: "80%",
          },
        }}
      >
        <Typography variant="h3">
          The
          <span
            style={{
              color: "#007DFE",
              display: "inline-block",
              marginLeft: "5px",
            }}
          >
            Artiste
          </span>
          <span
            style={{
              color: "rgb(255,138,0)",
              display: "inline-block",
              marginLeft: "5px",
            }}
          >
            AI
          </span>
        </Typography>
        <Box
          sx={{
            textAlign: "center"
          }}
        >
          <Typography
            style={{
              marginTop: "20px",
            }}
          >
            Traditional content generation is time-consuming and
            resource-intensive, requiring shoots, reshoots, editing, and
            post-processing. Personalizing content for a diverse audience is
            difficult at scale, driving up production costs due to repetitive
            tasks and manpower. Translating for global audiences often loses
            subtle nuances, and the lack of lip-sync in dubbed content reduces
            viewer engagement.
            <br />
            <br />
          </Typography>
          <Typography
            style={{
                textAlign: "center",
            }}
            variant="h6"
          >
            Kroop AI’s Generative AI solution revolutionizes audio and video
            content creation by addressing key challenges:
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: "30px",
          textAlign: "left",
        }}
        sx={{
          width: {
            xs: "90%",
            sm: "80%",
            md: "70%",
            lg: "80%",
          },
        }}
      >
        <Grid 
          container
          alignItems="center"
        >
          <Grid item xs={12} sm={6} md={7}>
            <ul
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <li>
                <b>Text-to-video with lip-sync:</b> Create multilingual videos
                from text input, supporting all Indian and international
                languages, with natural-sounding AI-generated voices for
                voiceovers.
              </li>
              <li
                style={{
                  marginTop: "15px",
                }}
              >
                <b>Extensive avatar database:</b> Choose from a large in-house
                selection of avatars. The AI also summarizes lengthy audio or
                video content for quick, concise consumption.
              </li>
              <li
                style={{
                  marginTop: "15px",
                }}
              >
                <b>Personalized content:</b> Tailors content for mass corporate
                communication or personalized messaging for e-commerce
                platforms.
              </li>
            </ul>
          </Grid>
          <Grid item xs={12} sm={6} md={5}>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  width: "300px",
                }}
              >
                <img
                  src={Artiste1}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
              <Box
                style={{
                  width: "300px",
                }}
              >
                <img
                  src={Artiste2}
                  style={{
                    width: "100%",
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          textAlign: "center",
          marginTop: "30px",
        }}
        sx={{
          width: {
            xs: "90%",
            sm: "80%",
            md: "70%",
            lg: "80%",
          },
        }}
      >
        <Box>
          <Typography
            variant="h6"
          >
            Please contact us at{" "}
            <span style={{ color: "green" }}>info@kroop.ai</span> or fill in the
            form below to schedule a call or to learn more about The Artiste
            Studio & its usage.
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          marginTop: "30px",
        }}
      >
        <ContactForm />
      </Box>
    </Box>
  );
};

export default GenerateVideo;
